<script lang="ts">
    import {onMount, onDestroy} from 'svelte';
    import { GoogleAnalytics } from '@beyonk/svelte-google-analytics'

    import {appConfigStore} from '../stores.js';
    import {setLocale, t} from '../lib/i18n.js';
    import {observeWindowWidth, windowWidth} from '../lib/resizable';

    import TheHeader from "../Components/TheHeader.svelte";
    import TheFooter from "../Components/TheFooter.svelte";
    import FeedbackWindow from "../Components/FeedbackWindow.svelte";

    let resizeObserver: ResizeObserver;
    let appConfig = $appConfigStore;

    onMount(() => {
        setLocale(appConfig.app_locale);
        resizeObserver = observeWindowWidth();
        windowWidth.set(window.innerWidth);
    });

    onDestroy(() => {
        // resizeObserver.disconnect();
    });

</script>
<svelte:head>
    <meta property="og:type" content="article">
    <meta property="og:site_name" content="{$t('head.meta_site_name', {city: appConfig.city_name})}"/>
    <meta property="og:image" content="/public/assets/images/Banner-400.jpg"/>
    <meta property="fb:app_id" content="{appConfig.facebook_app_id}"/>
    <meta name="google-site-verification" content="{appConfig.analytics.google_site_verification}"/>
</svelte:head>

<GoogleAnalytics properties={[appConfig.analytics.google_tag_measurement_id]} />
<div id="fb-root"></div>

<TheHeader> </TheHeader>

<div class="invisible-height"></div>

<div class="section-content">
    <slot />
</div>

<TheFooter> </TheFooter>

{#if $appConfigStore.version_feedback}
    <FeedbackWindow/>
{/if}
