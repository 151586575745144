<script lang="ts">
import {t} from "../lib/i18n.js";

let isActive: boolean = false;

function openWindow() {
    isActive = true;
}

function closeWindow() {
    isActive = false;
}
</script>

<div on:click={openWindow} class="evaluation" id="evaluation">
    <img class="evaluation-img" src="/public/assets/images/icons/feedback.svg" alt="Feedback">
</div>

{#if isActive}
    <div class="rate {isActive ? 'd-block' : 'd-none'}" id="rate">
        <div on:click="{closeWindow}" class="d-flex justify-content-end" id="rate-close">
            <img class="rate_close" id="rate_close" src="/public/assets/images/icons/close.svg" alt="Close">
        </div>
        <div class="rate_title mb-3">{$t('texts.rate_site_title')}</div>
        <div class="d-flex justify-content-center">
            <a on:click="{closeWindow}" rel="noopener noreferrer" href="https://forms.gle/KtzzRCBhr5MYCYZk8" target='_blank'
               class="rat_ans mb-3">
                {$t('texts.rate_site_link')}
            </a>
        </div>
    </div>
{/if}

<style lang="less">
    .rate {
        position: fixed;
        bottom: 40px;
        right: 40px;
        background: #469efc;
        padding: 20px;
        border-radius: 20px;
        width: 300px;
        display: none;
        z-index: 1000;
    }

    .rate_title {
        color: #FFF;
        font-size: 20px;
        font-weight: 100 !important;
        font-family: e-Ukraine sans-serif;
        text-align: center;
    }

    .rate_close {
        width: 20px;
        cursor: pointer;
    }

    .rat_ans {
        cursor: pointer;
        padding: 10px 16px;
        border-radius: 5555px;
        width: auto;
        font-family: e-Ukraine sans-serif;
        font-size: 12px !important;
        font-weight: 400;
        border: 1px solid #ffffff;
        color: #ffffff !important;
        background: #0061F319;
        text-decoration: none;
    }

    .rat_ans:hover {
        border: 1px solid #0061F3;
        color: #0061F3 !important;
    }

    .evaluation-img {
        width: 20px;
        height: 18px;
    }

    .evaluation {
        position: fixed;
        bottom: 40px;
        right: 40px;
        cursor: pointer;
        background: #469efc;
        border-radius: 20px;
        padding: 10px;
        z-index: 999;
        height: 38px;
    }

    .evaluation:hover {
        background: #3091fa;
    }

    @media (max-width: 520px) {
        .rate {
            width: auto;
            max-width: 300px;
        }
    }
</style>
