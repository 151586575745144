import { GtagEventModel } from '../models/gtag-event.model';
import { ga } from '@beyonk/svelte-google-analytics'
interface Window {
  gtag?: (...args: any[]) => void,
  dataLayer?: any[],
}

export function addGoogleTag(gtagEventModel: GtagEventModel) {

  ga.addEvent(gtagEventModel.event, {
        event_category: gtagEventModel.category,
        event_label: gtagEventModel.label,
    });
}
