<script lang="ts">
    import {appConfigStore} from "../stores";
    import {t} from '../lib/i18n.js';
    import {toggleVersion} from "../lib/toggleVersion";
    import {isMobile} from "../lib/resizable";
    import {addGoogleTag} from "../lib/analytics";

    import AppMenu from "./Menu.svelte";

    let appConfig = $appConfigStore;

    function changeLang(event: MouseEvent) {
        const target = event.target as HTMLInputElement;
        document.cookie = `lang=${target.value};path=/`;
        document.location.href = window.location.href;
    }

    function handleOldVersion() {
        addGoogleTag({
            event: 'go_to_old_version',
            category: 'version',
            label: 'The old version of the site is opened.',
        });

        toggleVersion();
    }

</script>
<header class="d-flex align-items-center justify-content-between header">
    <div class="d-flex align-items-center">
        <a class="header__logo d-flex align-items-center" href="/">
            <img class="site-logo" src="/public/assets/images/site-logo.svg" alt="Logo">
            <div class="mx-2">{appConfig.domain}</div>
        </a>
        {#if !$isMobile}
            <div class="header__title mx-3">{$t('head.new_name_street', {city: appConfig.city_name})}</div>
        {/if}
    </div>

    <div class="d-flex align-items-center">
        <button type="button" on:click="{handleOldVersion}" class="version-button">{$t('head.old_version')}</button>
            <div class="d-flex align-items-center street">
                <div class="mx-2 street__number">{appConfig.street_count}</div>
                <div class="street__text"> {$t("head.street_renamed")}</div>
            </div>
        <div class="d-flex align-items-center nowrap local-flag">
            {#each  Object.entries(appConfig.available_languages) as [locale, _]}
                <input type="image"
                       src="{appConfig.available_languages[locale]['link']}"
                       alt="{appConfig.available_languages[locale]['name']}"
                       class="lang"
                       on:click="{changeLang}"
                       value="{locale}">
            {/each}
            <AppMenu
                last_update="{appConfig.last_update}"
                about_link="/about"
                docs_link="/docs"
                feedbacks_link="/feedbacks"
                subscribe_link="/subscribe"
                app_locale="{appConfig.app_locale}"
                domain="{appConfig.domain}"
            >
            </AppMenu>
        </div>
    </div>
</header>

<style lang="less">
    .header {
        font-family: e-Ukraine sans-serif;
        background: #ffffff;
        padding: 28px 80px;

        @media (max-width: 520px) {
            padding: 13px 16px;
        }

        &__logo {
            text-decoration: none;
            color: #030303;
            font-size: 24px;
            font-weight: 500;
            &:hover {
                color: #030303;
            }
        }
        &__title {
            color: #818181;
            font-size: 16px;
            font-weight: 100;
        }
    }

    .street {
        &__number {
            color: #030303;
            font-size: 30px;
            font-weight: 500;
        }
        &__text {
            width: 133px;
            color: #030303;
            font-size: 12px;
            font-weight: 100;
            text-align: left;
        }

        @media (max-width: 767px) {
                display: none !important;
        }
    }

    .local-flag {
        width: 30px;
        height: 30px;
        margin-right: 80px;
    }

    .version-button{
        padding: 10px 15px;
        border-radius: 5555px;
        border: 2px solid #007bff;
        color: #007bff;
        margin-right: 20px;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        @media (max-width: 920px) {
            display: none;
        }
    }

    .site-logo {
        width: 32px;
        height: 32px;
    }

</style>
