export default {
    "en-US": {
        "menu.main": "Main",
        "menu.about": "About the project",
        "menu.docs": "To the developers",
        "menu.feedbacks": "Feedback",
        "menu.subscribe": "Subscribe",
        "footer.developed_in": "Developed in:",
        "footer.last_update": "All Rights Reserved. Last update: {{last_update}}",
        "months": [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ],
        "street_names": "Street names",
        "all": "All",
        "recent": "Recent",
        "decision": "Decision",
        "eponim_type.person": "In honor of a person",
        "eponim_type.object": "In honor of an object",
        "eponim_type.historical_name": "Historical name",
        "eponim_type.subject": "In honor of a subject",
        "street.old_name": "Old name",
        "street.new_name": "New name",
        "street.district": "District",
        "street.rename_date": "Date of renaming",
        "street.download": "Download the Regulations",
        "details": "More details",
        "on_google_maps": "Show in Google maps",
        "qr-block.text": "Join Onix and improve the world with us!",
        "for": "For",
        "join_to": "Join to",

        "head.old_version": "Go to the old version",
        "head.new_version": "Go to the new version",
        "head.new_name_street": "New street names in {{city}}",
        "head.street_renamed":"streets were renamed",

        "home.list": "List",
        "home.map": "Map",

        "texts.new_version": "Go to the new version",
        "texts.old_version": "Go to the old version",
        'texts.example_email': 'example@gmail.com',
        'texts.rate_site_link': 'Answer',
        'texts.rate_site_title': 'Do you like the new design?',

        "subscribe.title": "Subscribe on streets changes",
        "subscribe.subscribe" :"Subscribe",
        "subscribe.unsubscribe": "Unsubscribe from notifications",
        "subscribe.text": "Subscribe to street rename notifications",

        "about.idea_creation": "The idea of creation",
        "about.idea_creation_site_appeared_after_site_was_noticed_in_network": "The idea of creating a site appeared after the site was noticed in the network",
        "about.participated_in_development_of_site": "Participated in the development of the site",
        "about.idea_preparation_of_information_about_name_changes": "idea, preparation of information about name changes",
        "about.preparation_of_information_about_streets": "preparation of information about streets",
        "about.developer": "the developer",
        "about.designer": "designer",
        "about.rewritten_technical_part": "Starting from 2022, technical part of the site was completely rewritten by a new team, consisting of",
        "about.and": "and",
        "about.support_of_project": ". The team bring new technologies, features and tools. Currently fully support development part of the project.",
        "about.send_complaints_and_suggestions_to_email": "Send complaints and suggestions by e-mail",
        "about.decommunization_of_objects_is_not_going_fast": "The decommunization of objects in {{city}} is not going fast, and it is very inconvenient to observe these changes while reading the resolutions of the executive committee of the city council. At the same time, mapping services do not have time to track and correctly display street names or find the right addresses.",
        "about.organizer": "organizer",
        'about.moral_support': 'moral support',

        "about.Vladimir_Gordienko": "Vladimir Gordienko",
        "about.Dmytro_Durach": "Dmytro Durach",
        "about.Konstantin_Svidzinskyi": "Konstantin Svidzinskyi",
        "about.Konstantin_Altukhov": "Konstantin Altukhov",
        "about.Sergey_Holin": "Sergey Holin",
        "about.Maxim_Sinchenko": "Maxim Sinchenko",
        "about.Bohdan_Stasiuk": "Bohdan Stasik",
        "about.Mykola_Stadnik": "Mykola Stadnik",
        "about.Ihor_Rud": "Ihor Rud",
        "about.Volodymyr_Mikhav": "Volodymyr Mikhav",
        "about.Tortubekova_Kateryna": "Tortubekova Kateryna",

        'docs.title': 'How to use the {{domain}} API',
        'docs.data_file_presented_object': 'The data in the file is presented as an object',
        'docs.text.paragraph.1': 'The object contains information about renamed streets, dead ends, alleys, etc. in the city. Each object has details such as id (object identifier), old_name and new_name (old and new name of the object), act_id (identifier of the act under which a certain object was renamed), area_id (identifier of the area where the object is located), lat and lng (latitude and longitude coordinates of the object), object_line (array of coordinates for building the object\'s polyline on the map), and more. Each renaming object has mandatory fields: object_type - the type of the object (street, alley, park, square...), old_name, new_name, as well as a set of optional fields such as eponim, eponim_type, other.',
        'docs.text.paragraph.2' : 'The eponim field contains a link to the subject of the renaming of the current object (in other words, in honor of whom or what the renaming of a particular street or place was made). eponim_type, in turn, is the type of the subject, which is an integer, and its value corresponds to:',
        'docs.specific_personality': 'specific personality',
        'docs.certain_historical_event': 'certain historical event',
        'docs.geographic_object': 'geographic object',
        'docs.some_organization_or_community_for_example_family': 'some organization or community, for example - a family',
        'docs.link_to_api_documentation': 'Links to API documentation',
        'docs.documentation': 'documentation',

        'feedbacks.feedback': 'Feedback',
        'feedbacks.your_name': 'Your name',
        'feedbacks.your_name.placeholder' : 'Enter your name',
        'feedbacks.your_mail': 'Your mail',
        'feedbacks.your_mail.placeholder' : 'Enter your mail',
        'feedbacks.your_message': 'Enter your message text',
        'feedbacks.your_message.placeholder': 'Message text',
        'feedbacks.send': 'Send',

        'messages.sent': 'Sent',

        'subscription.title': 'Subscribe on streets changes',
        'subscription.subscribe': 'Subscribe',
        'subscription.text': 'Subscribe to street rename notifications',

        'auth.login': 'Login',
        'auth.email': 'Email',
        'auth.password': 'Password',
        'auth.sign_in': 'Sign in',

        'streets.object_type.street': 'street',
        'streets.object_type.lane': 'lane',
        'streets.object_type.park': 'park',
        'streets.object_type.squere': 'squere',
        'streets.object_type.boulevard': 'boulevard',
        'streets.object_type.roadway': 'roadway',
        'streets.object_type.blind-alley': 'blind alley',
        'streets.object_type.avenue': 'avenue',
        'streets.object_type.area': 'area',
        'streets.object_type.quay': 'quay',
        'streets.object_type.line': 'line',
        'streets.object_type.driveway': 'driveway',
        'streets.object_type.impasse': 'driveway',

        // meta_tags
        'head.meta_site_name':'Renaming a street in {{city}} in connection with the requirement of the law on decommunization',

        'home.meta_title': '{{domain}} - New street names in {{city}}',
        'home.meta_description': 'New street names in {{city}}, decommunization',
        'home.meta_keywords': '{{city}}, rename, decommunization',

        'about.meta_title': '{{domain}} - About the project',
        'about.meta_description': 'About the project',
        'about.meta_keywords': 'about the project, idea of creation, decommunization of objects',

        'docs.meta_title': '{{domain}} - How to use the {{domain}} API',
        'docs.meta_description': 'How to use the {{domain}} API',
        'docs.meta_keywords': 'developers, how to use api, object, fields, old name, new name',

        'feedbacks.meta_title': '{{domain}} - Feedback',

        'streets.meta_title': '{{domain}} - How {{name}} {{object_type}} was renamed in {{city}}',
        'streets.meta_og_title': '{{new_name}} - renamed in {{old_name}}',
        'streets.meta_description': '{{old_name}}, renamed in {{new_name}}, by decision of the city council from {{date}}. {{city}}, rename {{object_type}}, decommunization',
        'streets.meta_og_description': '{{old_name}}, renamed in {{new_name}}, by decision of the city council from {{date}}. The old name of {{new_name}} - {{old_name}}',
        'streets.meta_keywords': '{{city}}, renaming, {{object_type}}, {{old_name}}, {{new_name}}, decommunization, how was it renamed',
    },
    "uk-UA": {
        "menu.main": "Головна",
        "menu.about": "Про проект",
        "menu.docs": "Розробникам",
        "menu.feedbacks": "Зворотній зв'язок",
        "menu.subscribe": "Підписатись",
        "footer.developed_in": "Розроблено в:",
        "footer.last_update": "Всі права захищені. Останнє оновлення: {{last_update}}",
        "months": [
            "Січня", "Лютого", "Березня", "Квітня", "Травня", "Червня",
            "Липня", "Серпня", "Вересня", "Жовтня", "Листопада", "Грудня"
        ],
        "street_names": "Назви вулиць",
        "all": " Усі",
        "recent": "Нещодавні",
        "decision": "Рішення",
        "eponim_type.person": "На честь особи",
        "eponim_type.object": "На честь об'єкта",
        "eponim_type.historical_name": "Історична назва",
        "eponim_type.subject": "На честь суб'єкта",
        "street.old_name": "Стара назва",
        "street.new_name": "Нова назва",
        "street.district": "Район",
        "street.rename_date": "Дата перейменування",
        "street.download": "Завантажити Розпорядження",
        "details": "Детальніше",
        "on_google_maps": "Показати в гугл картах",
        "qr-block.text": "Приєднуй- тесь до Оніксу та покращу- йте світ разом із нами!",
        "for": "За",
        "join_to": "Приєднуйся до",
        "head.old_version": "Перейти на стару версію",
        "home.list": "Список",
        "home.map": "Карта",

        "head.street_renamed": "вулиць перейменовано",
        "head.new_version": "Перейти на нову версію",
        "head.new_name_street": "Нові назви вулиць у місті {{city}}",

        'texts.example_email': 'example@gmail.com',
        'texts.rate_site_link': 'Відповісти',
        'texts.rate_site_title': 'Чи подобається вам оновлений дизайн?',


        "about.idea_creation": "Ідея створення",
        "about.idea_creation_site_appeared_after_site_was_noticed_in_network": "Ідея створення сайту з\'явилася після того, як у мережі потрапив в поле зору сайт",
        "about.decommunization_of_objects_is_not_going_fast": "Декомунізація об’єктів у місті {{city}} йде нешвидко і спостерігати за цими змінами, читаючи постанови виконавчого комітету міської ради дуже незручно. У той же самий час і картографічні сервіси не встигають відслідковувати і правильно показувати назви вулиць, чи знаходити потрібні адреси.",
        "about.participated_in_development_of_site": "У розробці сайту приняли участь",
        "about.Bohdan_Stasiuk": "Богдан Стасюк",
        "about.idea_preparation_of_information_about_name_changes": "ідея, підготовка інформації про зміни назв",
        "about.Maxim_Sinchenko": "Максим Сінченко",
        "about.preparation_of_information_about_streets": "підготовка інформації про вулиці",
        "about.Sergey_Holin": "Холін Сергій",
        "about.Konstantin_Altukhov": "Константин Алтухов",
        "about.developer": "розробник",
        "about.Konstantin_Svidzinskyi": "Константин Свідзінський",
        "about.designer": "дизайнер",
        "about.Dmytro_Durach": "Дмитро Дурач",
        "about.Ihor_Rud": "Ігор Рудь",
        "about.Volodymyr_Mikhav": "Володимир Міхав",
        "about.send_complaints_and_suggestions_to_email": "Скарги та пропозиції надсилайте на електронну пошту",

        "about.rewritten_technical_part": "Починаючи з 2022 року технічну частину сайту було повністю переписано новою командою у складі якої",
        "about.support_of_project": ". Команда впровадила нові технології, функціонал та інструменти. Наразі повністю підтримує розробку проекту.",
        "about.Vladimir_Gordienko": "Володимир Гордієнко",
        "about.Tortubekova_Kateryna": "Тортубекова Катерина",
        "about.Mykola_Stadnik": "Микола Стаднік",
        "about.and": "та",
        "about.organizer": "організатор",
        'about.moral_support': 'моральна підтримка',

        'docs.title': 'Як використовувати API {{domain}}',
        'docs.data_file_presented_object': 'Дані у файлі представлені у вигляді об\'єкта',
        'docs.text.paragraph.1': 'Обʼєкт містить інформацію щодо перейменованих вулиць, тупиків, провулків і тп.  у місті. Кожен обʼєкт має відомості id (ідентифікатор обʼєкта), old_name та new_name (стара та нова назва обʼєкта), act_id (ідентифікатор акту, за яким було перейменовано певний обʼєкт), area_id (ідентифікатор району, де знаходиться обʼєкт), lat і lng (широта та довгота координат обʼєкта), object_line (масив координат для побудови протяжності  обʼєкта на карті) та інше. Кожен об\'єкт перейменування має обов\'язкові поля: object_type - тип об\'єкта (вулиця, провулок, парк, площа...), old_name, new_name, а також набір необов\'язкових полів , таких як eponim, eponim_type, other.',
        'docs.text.paragraph.2': 'Поле eponim - посилання на суб\'єкта перейменування поточного об\'єкта ( інакше кажучи , в честь кого або чого зроблено перейменування конкретної вулиці чи місця ). eponim_type в свою чергу - тип суб\'єкта, це ціле число, значення якого відповідає :',
        'docs.documentation': 'документація',
        'docs.specific_personality': 'конкретна особистість',
        'docs.certain_historical_event': 'певну історичну подію',
        'docs.geographic_object': 'географічний об\'єкт',
        'docs.some_organization_or_community_for_example_family': 'деяка організація або співтовариство , наприклад - сім\'я',
        'docs.link_to_api_documentation': 'Посилання на API документацію',

        'feedbacks.feedback': 'Зворотній зв\'язок',
        'feedbacks.your_name': 'Ваше ім\'я',
        'feedbacks.your_name.placeholder': 'Введіть ваше ім\'я',
        'feedbacks.your_mail': 'Ваша пошта',
        'feedbacks.your_mail.placeholder': 'Введіть вашу пошту',
        'feedbacks.your_message': 'Введіть текст повідомлення',
        'feedbacks.your_message.placeholder': 'Текст повідомлення',
        'feedbacks.theme': 'Тема',
        'feedbacks.message': 'Текст повідомлення',
        'feedbacks.send': 'Надіслати',

        'messages.sent': 'Надіслано',

        'subscription.title': 'Підписатись на оновлення вулиць',
        'subscription.subscribe': 'Підписатись',
        'subscription.text': 'Підписатися на сповіщення щодо перейменування вулиць',

        'auth.login': 'Вхід',
        'auth.email': 'Email',
        'auth.password': 'Пароль',
        'auth.sign_in': 'Увійти',

        'streets.object_type.street': 'вулицю',
        'streets.object_type.lane': 'провулок',
        'streets.object_type.park': 'парк',
        'streets.object_type.squere': 'сквер',
        'streets.object_type.boulevard': 'бульвар',
        'streets.object_type.roadway': 'проїзд',
        'streets.object_type.blind-alley': 'тупик',
        'streets.object_type.avenue': 'проспект',
        'streets.object_type.area': 'район',
        'streets.object_type.quay': 'набережну',
        'streets.object_type.line': 'провулок',
        'streets.object_type.driveway': 'проїзд',
        'streets.object_type.impasse': 'тупик',

        // meta_tags
        'head.meta_site_name':"Перейменування вулиць в місті {{city}} в зв'язку з вимогою закону про декомунізацію",

        'home.meta_title': '{{domain}} - Нові назви вулиць у місті {{city}}',
        'home.meta_description': 'Нові назви вулиць у місті {{city}}, декомунізація',
        'home.meta_keywords': '{{city}}, перейменування, декомунізація',

        'about.meta_title': '{{domain}} - Про проект',
        'about.meta_description': 'Про проект',
        'about.meta_keywords': "про проект, ідея створення, декумунізація об\'єктів",

        'docs.meta_title': '{{domain}} - Як використовувати API {{domain}}',
        'docs.meta_description': 'Як використовувати API {{domain}}',
        'docs.meta_keywords': "розробникам, як використовувати api, об\'єкт, поля, стара назва, нова назва",

        'feedbacks.meta_title': '{{domain}} - Зворотній звязок',

        'streets.meta_title': '{{domain}} - Як перейменували {{object_type}} {{old_name}} в м. {{city}}',
        'streets.meta_og_title': '{{new_name}} - Як перейменували {{object_type}} {{old_name}} в м. {{city}}',
        'streets.meta_description': '{{old_name}}, перейменували в {{new_name}}, рішенням міської ради від {{date}}. {{city}}, перейменування {{object_type}}, декомунізація',
        'streets.meta_og_description': '{{old_name}}, перейменували в {{new_name}}, рішенням міської ради від from {{date}}. Cтара назва  {{new_name}} - {{old_name}}',
        'streets.meta_keywords': '{{city}}, перейменування, {{object_type}}, {{old_name}}, {{new_name}}, декомунізація, як переназвали',
    },
};
