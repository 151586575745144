import { writable, derived } from 'svelte/store';
export const windowWidth = writable<number>(0);

export const isMobile = derived(
  windowWidth,
  ($windowWidth: number) => $windowWidth <= 992
);

export function observeWindowWidth(): ResizeObserver {
  const resizeObserver = new ResizeObserver(entries => {
    const newWidth = entries[0].contentRect.width;
    windowWidth.set(newWidth);
  });
  resizeObserver.observe(document.body);
  windowWidth.set(document.body.getBoundingClientRect().width);
  return resizeObserver;
}
