<script lang="ts">
    import { page } from '@inertiajs/svelte';

    import {t} from '../lib/i18n.js';
    import {addGoogleTag} from "../lib/analytics";

    export let last_update: number = $page.props.app.last_update,
        current_date: number = $page.props.app.current_date;

    function onClick(event, label) {
        addGoogleTag({
            event: event,
            event_category: 'page',
            event_label: `${label} page is opened.`,
        });
    }
</script>
<footer class='footer'>
    <div class="d-flex align-items-center justify-content-between nowrap pt-4 pb-2">
        <div class="d-flex align-items-center flex-wrap">
            <a href="/" class="footer-menu pb-2">{$t('menu.main')}</a>
            <a href="/about" on:click={() => onClick('open_about_page_in_new_version', 'About')}
               class="footer-menu pb-2">{$t('menu.about')}</a>
            <a href="/docs" on:click={() => onClick('open_docs_page_in_new_version', 'Docs')}
               class="footer-menu pb-2">{$t('menu.docs')}</a>
            <a href="/feedbacks" on:click={() => onClick('open_feedbacks_page_in_new_version', 'Feedbacks')}
               class="footer-menu pb-2">{$t('menu.feedbacks')}</a>
            <a href="/subscribe" on:click={() => onClick('open_subscribe_page_in_new_version', 'Subscribe')}
               class="footer-menu pb-2">{$t('menu.subscribe')}</a>
        </div>
        <div class="d-flex flex-wrap">
            <a class="d-flex align-items-center footer-social-media mb-2" target="_blank" rel="noopener noreferrer"
               href="https://twitter.com/share">
                <img src="/public/assets/images/icons/twitter-black.svg" alt="Footer Twitter">
                <span>Tweet</span>
            </a>
            <a class="d-flex align-items-center footer-social-media mb-2" target="_blank" rel="noopener noreferrer"
               href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Frename.kr.ua%2Ffeedbacks&display=popup&ref=plugin&src=share_button">
                <img src="/public/assets/images/icons/facebook-black.svg" alt="Footer Facebook">
                <span>Facebook</span>
            </a>
        </div>
    </div>
    <div class="underline"></div>
    <div class="d-flex align-items-center justify-content-between mt-4 flex-wrap">
        <div class="copyrything mb-3">
            &#169; 2016 - { current_date } Rename. All Rights Reserved. Last update:
            { last_update }
        </div>
        <div class="footer-develop-info mb-3">
            <div>{$t('footer.developed_in')}</div>
            <a href="https://onix-systems.com/"  class="d-inline-flex" target="_blank" rel="noopener noreferrer">
                <img src="/public/assets/images/icons/onix.svg" alt="Footer Onix">
            </a>
        </div>
    </div>
</footer>
<style lang="less">
    .footer-develop-info {
        display: flex;
        //align-items: center;
        color: #030303;
        font-family: e-Ukraine sans-serif;
        font-weight: 400;
        font-size: 13px;
    }

    .footer-develop-info img {
        margin-left: 8px;
    }

    .footer-menu {
        font-family: e-Ukraine sans-serif;
        font-size: 13px;
        font-weight: 400;
        color: #030303;
        text-decoration: none;
        padding-right: 16px;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer-social-media {
        text-decoration: none;
        color: #030303;
        font-family: e-Ukraine, sans-serif !important;
        font-size: 13px;
        font-weight: 400;
        border-radius: 7px;
        border: 1px solid #F1F1F1 !important;
        padding: 6px 13px;
        width: min-content;
        margin-left: 20px;
    }

    .footer-social-media span {
        padding: 0 5px;
    }

    .copyrything {
        color: #818181;
        font-family: e-Ukraine sans-serif;
        font-size: 13px;
        font-weight: 300;
    }

    .footer {
        font-family: e-Ukraine sans-serif;
        background: #ffffff !important;
        margin-top: auto;
        padding: 0 80px 10px 80px;

        .underline {
            width: 100%;
            height: 1px;
            background: #030303 !important;
        }
    }

    @media (max-width: 520px) {
        .footer {
            padding: 10px 16px 10px 16px !important;
        }
    }
</style>
