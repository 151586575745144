<script lang="ts">
    import {onMount} from 'svelte';

    import {t, setLocale} from '../lib/i18n.js';
    import {toggleVersion} from "../lib/toggleVersion";
    import {addGoogleTag} from '../lib/analytics';

    export let last_update: string,
        about_link: string,
        docs_link: string,
        feedbacks_link: string,
        subscribe_link: string,
        app_locale: string,
        domain: string;

    let isOpenMenu = false;
    let currentRoute: string = '/';
    let linkFacebook = 'https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Frename.kr.ua%2Ffeedbacks&display=popup&ref=plugin&src=share_button';
    let linkOnix = 'https://onix-systems.com/';
    let linkTwitter = 'https://twitter.com/share';

    setLocale(app_locale);

    function handleButtonClick() {
        isOpenMenu = !isOpenMenu;
        if (isOpenMenu) {
            addGoogleTag({
                event: 'open_menu',
                category: 'open',
                label: 'Menu is opened.'
            });
        }
    }

    onMount(() => {
        updateCurrentRoute();
    });

    function updateCurrentRoute() {
        currentRoute = window.location.pathname;
    }

    function handleOldVersion() {
        addGoogleTag({
            event: 'go_to_old_version',
            category: 'version',
            label: 'The old version of the site is opened.'
        });

        toggleVersion()
    }
</script>

<section class="menu">
    {#if (!isOpenMenu)}
        <button class="d-flex" type="button" tabindex="0" on:click={handleButtonClick}>
            <img src="/public/assets/images/icons/menu.svg" alt="Menu">
        </button>
    {:else}
        <div class="menu-wrapper position-fixed d-flex flex-column visible">
            <div class=" d-flex align-items-center justify-content-between">
                <a href="/" class="menu-logo my-3 mx-4 d-flex align-items-center">
                    <img src="/public/assets/images/site-logo.svg" alt="Logo">
                    <div class="mx-2">{domain}</div>
                </a>
                <button type="button" tabindex="0" on:click={handleButtonClick} class="ms-auto cursor-pointer p-4">
                    <img src="/public/assets/images/icons/close.svg" alt="Close">
                </button>
            </div>
            <div class="menu-nav">
                <div class="nav-title">
                    <a href="/" class="{currentRoute === '/' ? 'nav-active' : ''}">
                        { $t("menu.main") }
                    </a>
                </div>
                <div class="nav-title">
                    <a href="{about_link}"
                       class="{currentRoute === about_link ? 'nav-active' : ''}"
                       on:click={() => addGoogleTag({
                            event: 'open_about_page_in_new_version',
                            category: 'page',
                            label: 'About page is opened.'
                          })}>
                        { $t("menu.about") }
                    </a>
                </div>
                <div class="nav-title">
                    <a href="{docs_link}"
                       class="{currentRoute === docs_link ? 'nav-active' : ''}"
                       on:click={() => addGoogleTag(
                           {
                             event: 'open_about_docs_in_new_version',
                             category: 'page',
                             label: 'Docs page is opened.'
                            })}>
                        { $t("menu.docs") }
                    </a>
                </div>
                <div class="nav-title">
                    <a href="{feedbacks_link}"
                       class="{currentRoute === feedbacks_link ? 'nav-active' : ''}"
                       on:click={() => addGoogleTag({
                            event: 'open_feedbacks_page_in_new_version',
                            category: 'page',
                            label: 'Feedbacks page is opened.'
                          })}>
                        { $t("menu.feedbacks") }
                    </a>
                </div>
                <div class="nav-title">
                    <a href="{subscribe_link}"
                       class="{currentRoute === subscribe_link ? 'nav-active' : ''}"
                       on:click={() => addGoogleTag({
                            event: 'open_subscribe_page_in_new_version',
                            category: 'page',
                            label: 'Subscribe page is opened.'
                          })}>
                        { $t("menu.subscribe") }
                    </a>
                </div>
            </div>
            <div class="menu-footer">
                <div class="menu-version-button">
                    <a href="{window.location.href}" on:click={handleOldVersion}>
                        { $t("head.old_version") }
                    </a>
                </div>
                <div class="develop-info">
                    <div> { $t("footer.developed_in") }</div>
                    <a href="{linkOnix}" target="_blank" rel="noopener noreferrer"><img src="/public/assets/images/icons/onix.svg" alt="Menu"></a>
                </div>
                <div class="d-flex">
                    <a class="d-flex align-items-center nav-social-media" target="_blank" rel="noopener noreferrer" href="{linkTwitter}">
                        <img src="/public/assets/images/icons/twitter.svg" alt="Menu">
                        <span>Tweet</span>
                    </a>
                    <a class="d-flex align-items-center nav-social-media" target="_blank" rel="noopener noreferrer" href="{linkFacebook}">
                        <img src="/public/assets/images/icons/facebook.svg" alt="Facebook">
                        <span>Facebook</span>
                    </a>
                </div>
                <hr>
                <div>
                    <div class="menu-footer-information">
                        © 2016 - {new Date().getFullYear()} Rename.
                    </div>
                    <div class="menu-footer-information">
                        {$t("footer.last_update", {last_update})}
                    </div>
                </div>
            </div>
        </div>
    {/if}
</section>

<style lang="less">
    .menu-version-button {
        padding: 10px 15px;
        border-radius: 5555px;
        border: 2px solid #007bff;
        color: #007bff;
        font-size: 14px;
        text-align: center;
        width: 100%;
        background: #FFFFFF;
        margin-bottom: 25px;
        display: none;
        cursor: pointer;
    }

    .menu-version-button a {
        text-decoration: none;
    }

    .menu-logo {
        text-decoration: none;
        color: #ffffff;
        font-family: e-Ukraine sans-serif;
        font-size: 24px;
        font-weight: 500;
    }

    .menu-wrapper {
        background-color: #072030;
        top: 0;
        right: -480px;
        width: 480px;
        height: 100%;
        transition: right 0.3s ease-out;
        z-index: 1001;
    }

    .menu-wrapper.visible {
        right: 0;
    }

    .menu-wrapper.visible.svelte-mlgmju.svelte-mlgmju {
        right: 0;
        overflow: auto;
    }

    .menu button {
        background: none;
        border: none;
    }

    .menu-nav {
        padding: 162px 55px 0 70px;
    }

    .nav-title a {
        color: #FFF;
        font-size: 30px;
        font-weight: 100 !important;
        font-family: e-Ukraine sans-serif;
        text-decoration: none;
        text-align: left;
    }

    .nav-title {
        padding-bottom: 26px;
    }

    .nav-active {
        color: #0061F3 !important;
    }

    .nav-social-media {
        text-decoration: none;
        color: #FFF;
        font-family: e-Ukraine, sans-serif !important;
        font-size: 13px;
        font-weight: 400;
        border-radius: 7px;
        border: 1px solid #51636E;
        padding: 6px 13px;
        width: min-content;
        margin: 20px 10px 20px 0;
    }

    .nav-social-media span {
        padding: 0 5px;
    }

    .menu-footer {
        margin-top: auto;
        padding: 0 70px 50px 70px;
    }

    .menu-footer hr {
        opacity: 10%;
        background: #FFF;
        margin-bottom: 20px;
    }

    .menu-footer-information {
        color: #51636E;
        font-family: e-Ukraine sans-serif;
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
    }

    .develop-info {
        display: flex;
        align-items: center;
        color: #51636E;
        font-family: e-Ukraine sans-serif;
        font-size: 13px;
        font-weight: 400;
    }

    .develop-info img {
        margin-left: 8px;
    }

    .site-logo {
        width: 32px;
        height: 32px;
    }

    @media (max-width: 920px) {
        .menu-version-button {
            display: block;
        }
    }

    @media (max-width: 767px) {
        .menu-wrapper {
            right: -380px;
            width: 380px;
        }

        .nav-title a {
            font-size: 24px;
        }

        .menu-nav {
            padding: 162px 55px 0 32px;
        }

        .menu-footer {
            margin-top: auto;
            padding: 0 32px 32px 32px;
        }
    }

    @media (max-width: 520px) {
        .menu-wrapper {
            width: 100%;
        }

        .menu-logo {
            opacity: 100%;
        }

        .menu-nav {
            padding: 50px 55px 20px 32px;
        }
    }
</style>
